import React, {useRef, useEffect, useState} from 'react';
import "./index.css"
import Wheel from "./wheel"
import colors from "../colors";
import {IconEdit} from "@douyinfe/semi-icons";
import {Button, SideSheet, Banner} from "@douyinfe/semi-ui"
import Edit from "./Edit";
import confetti from "canvas-confetti"

const audioWin = "https://f.cm321.cn/audio/newr.mp3"
const audioBa = "https://f.cm321.cn/audio/y2394.wav"
console.log = ()=>{}

let defaultZpInfo = {
    title: "幸运大转盘",
    options: [
        {
            text: '一等奖',
            color: colors[0],
            weight: 1
        },
        {
            text: '二等奖',
            color: colors[1],
            weight: 1
        },
        {
            text: '三等奖',
            color: colors[2],
            weight: 1
        },
        {
            text: '感谢参与',
            color: colors[3],
            weight: 1
        },
        {
            text: '特等奖',
            color: colors[4],
            weight: 1
        }]
}


// 子窗口代码
function sendMessage(data) {
    if (!window.opener) return
    window.lastPostMessage_ts = Date.now()
    let message = {
        from: 'qzpWeb1',
        ...data
    }
    return new Promise((resolve, reject) => {
        let handler = function (event) {
            // console.log("收到消息", event.data)
            if (event.data && event.data.from && event.data.from === "base_zp001") {
                window.removeEventListener('message', handler);
                window.lastPostMessage_ts = 0
                resolve(event.data); // 解析回复
            }
        }

        // 监听来自父窗口的响应
        window.addEventListener('message', handler, false);

        // 向父窗口发送消息
        window.opener.postMessage(message, "*");
    });
}


const App = () => {
    const [wheelSize, setWheelSize] = useState(300); // 设置转盘大小
    const [zpInfo, setZpInfo] = useState(defaultZpInfo);
    const [currentOption, setCurrentOption] = useState(null);
    const [rotation, setRotation] = useState(0);

    const audioWinRef = useRef(null)
    const audioBaRef = useRef(null)

    const [isAlive, setIsAlive] = useState(true)

    useEffect(() => {
        audioWinRef.current.src = audioWin
        audioBaRef.current.src = audioBa
        async function checkAlice() {
            if (window.lastPostMessage_ts && Date.now() - window.lastPostMessage_ts < 5000){
                return
            }
            let r = await sendMessage({
                type: "checkAlive"
            })
            // console.log("alive??",r)
            if (!r){
                setIsAlive(false)
            }else {
                setIsAlive(true)
            }
        }
        let checkAliveId = setInterval(async () => {
            checkAlice()
        },3000)


        let fn = async function () {
            // 获取zpInfo
            let data = await sendMessage({type: "getZpInfo"})
            if (!data) return
            let zpInfp = data.zpInfo
            if (!zpInfp) return
            console.log("获取到zpInfo", data)
            if (zpInfp.title || zpInfp.options){
                setZpInfo({
                    title: zpInfp.title,
                    options: zpInfp.options
                })
                setIsAlive(true)
            }
        }
        fn()

        return () => {
            clearInterval(checkAliveId)
        }
    }, [])


    // 窗口大小变化时，调整转盘大小
    useEffect(() => {
        const updateSize = () => {
            setWheelSize(Math.min(window.innerWidth, window.innerHeight) * 0.8);
        };
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const wheelContainerRef = useRef(null);
    const wheelInfo = useRef({
        currentOption: null,
        lastRotation: 0, // 上一次旋转的角度
        spinStatus: false, // 是否正在旋转
        spinInterval: 0,
        totalWeight: 0,
        firstOptionDeg: 0,
        degreesPerOption: 0,
        weightOptionIndex: [],
        result: {},
        lastRotate: 0
    })

    useEffect(() => {
        wheelInfo.current.currentOption = currentOption
    }, [currentOption]);

    useEffect(() => {
        let options = zpInfo.options
        //  options 变化时更新totalWeight 等参数
        const totalWeight = options.reduce((acc, option) => acc + option.weight, 0);
        const degreesPerOption = 360 / totalWeight;
        let firstOptionDeg = 360 * (options[0].weight / totalWeight);

        let weightOptionIndex = []
        for (let i = 0; i < options.length; i++) {
            for (let j = 0; j < options[i].weight; j++) {
                weightOptionIndex.push(i)
            }
        }
        wheelInfo.current.totalWeight = totalWeight;
        wheelInfo.current.firstOptionDeg = firstOptionDeg;
        wheelInfo.current.degreesPerOption = degreesPerOption;
        wheelInfo.current.weightOptionIndex = weightOptionIndex

    }, [zpInfo])

    const spinWheel = () => {
        let options = zpInfo.options
        if (wheelInfo.current.spinStatus) {
            // 正在旋转，就清理上一次的闭包
            clearInterval(wheelInfo.current.spinInterval)
        }

        // 从给定范围内获取随机值
        // 假如排除 选项-1
        let excludeText = []
        let leftIndex = []
        for (let i = 0; i < options.length; i++) {
            if (!excludeText.includes(options[i].text)) {
                leftIndex.push(i)
            }
        }

        let weightOptionIndex = wheelInfo.current.weightOptionIndex
        let leftWeightOptionIndex = weightOptionIndex.filter(item=>{
            return leftIndex.includes(item)
        })
        if (leftWeightOptionIndex.length === 0){
            leftWeightOptionIndex = weightOptionIndex
        }
        // 随机排序
        leftWeightOptionIndex.sort(() => Math.random() - 0.5);
        // console.log("leftWeightOptionIndex", leftWeightOptionIndex)

        let randomIndexOfLeft = Math.floor(Math.random() * leftWeightOptionIndex.length);
        let randomIndex = leftWeightOptionIndex[randomIndexOfLeft]
        console.log("rrr", randomIndexOfLeft, randomIndex)
        // 根据option 的 targetIndex 获该项目的 中心权重
        let accumulatedWeight = 0
        for (let i = 0; i < options.length; i++) {
            if (i === randomIndex) {
                // accumulatedWeight += options[i].weight / 2
                break
            }
            accumulatedWeight += options[i].weight
        }

        // console.log("accumulatedWeight", accumulatedWeight)
        // 获取该项的角度
        let randomDeg = accumulatedWeight * wheelInfo.current.degreesPerOption + 360 * 5
        // console.log("randomDeg", randomDeg)

        // console.log("结果是:", options[weightOptionIndex[randomIndex]], randomDeg)
        wheelInfo.current.result = options[randomIndex]

        let lastRotation = wheelInfo.current.lastRotation || 0;
        // 应该补正lastRotation 到初始化的位置，然后加上本次旋转的度数
        let diff = 360 - lastRotation % 360

        const newRotation = randomDeg + lastRotation + diff; // 至少旋转5圈
        wheelInfo.current.lastRotation = newRotation;
        setRotation(newRotation);
        wheelInfo.current.spinStatus = true

        wheelInfo.current.spinInterval = setInterval(() => {
            if (!wheelInfo.current.spinStatus) { // 不在旋转状态时，就停止
                clearInterval(wheelInfo.current.spinInterval)
            }
            if (wheelContainerRef.current) {
                const style = window.getComputedStyle(wheelContainerRef.current);
                const matrix = style.transform || style.webkitTransform || style.mozTransform;
                updateCurrentOption(extractRotationAngle(matrix));
            }
        }, 100); // 每100毫秒检查一次
    };

    const extractRotationAngle = (transformString) => {
        // console.log("transformString", transformString)
        const values = transformString.split('(')[1].split(')')[0].split(',');
        const a = values[0];
        const b = values[1];
        return Math.round(Math.atan2(b, a) * (180 / Math.PI));
    };

    const updateCurrentOption = (currentAngle) => {
        const firstOptionDeg = wheelInfo.current.firstOptionDeg;
        const degreesPerOption = wheelInfo.current.degreesPerOption;
        // console.log("当前角度111:", currentAngle % 360);
        // 修正角度，使得0度在12点钟方向
        let adjustedAngle = (currentAngle + firstOptionDeg / 2) % 360;

        if (adjustedAngle < 0) {
            adjustedAngle = 360 + adjustedAngle;
        }

        let options = zpInfo.options

        let accumulatedAngle = 0
        for (let i = 0; i < options.length; i++) {
            accumulatedAngle += options[i].weight * degreesPerOption;
            if (adjustedAngle < accumulatedAngle) {
                if (!wheelInfo.current.currentOption || options[i].text !== wheelInfo.current.currentOption.text) {
                    // console.log("切换选项", options[i], adjustedAngle, accumulatedAngle,i, options.length,degreesPerOption)
                    setCurrentOption(options[i]);
                    audioBaRef.current.currentTime = 0
                    audioBaRef.current.play()
                }
                break
            }
        }
    };

    const handleSpinEnd = async () => {
        // const style = window.getComputedStyle(wheelContainerRef.current);
        // const matrix = style.transform || style.webkitTransform || style.mozTransform;
        // updateCurrentOption(extractRotationAngle(matrix));
        // console.log("转盘停止,结果", wheelInfo.current.result)
        confetti({
            particleCount: 180,
            spread: 150,
            origin: { y: 0.6 }
        });

        audioWinRef.current.currentTime = 0
        audioWinRef.current.play()
        wheelInfo.current.spinStatus = false

        await sendMessage({
            type: "addResult",
            result: wheelInfo.current.result.text,
            title: zpInfo.title
        })
        console.log("发送结果")
    };


    const [editVisible, setEditVisible] = useState(false)
    const showEdit = () => {
        setEditVisible(true)
    }

    return (<>
        <audio id="audioWin" ref={audioWinRef} preload="auto"></audio>
        <audio id="audioBa" ref={audioBaRef} preload="auto"></audio>
        {!isAlive && <Banner type={'danger'} description={<>和多维表格的连接已断开，将无法保存转盘结果。请去多维表格重新打开</>} closeIcon={null} style={{marginBottom: 10}} />}
        <div className={'title'}>{zpInfo?.title}</div>
        <div className={'result-wrap'}>
            <div className={'result'}>{currentOption?.text || "??"}</div>
        </div>
        <div className={'wheel-wrap'} style={{width: wheelSize, height: wheelSize}}>
            <div ref={wheelContainerRef} className="wheel-container" style={{transform: `rotate(${rotation}deg)`}}
                 onTransitionEnd={handleSpinEnd}>
                <Wheel options={zpInfo.options} wheelSize={wheelSize}/>
            </div>
            <div className={'pointer'} onClick={spinWheel}>
                <div className={'text'}>Go</div>
            </div>
        </div>
        <div>
            <Button theme={'solid'} icon={<IconEdit/>} size={'large'} className={'editBtn'} onClick={showEdit}>
                编辑转盘
            </Button>
            <SideSheet title="编辑转盘" visible={editVisible} onCancel={() => {
                setEditVisible(false)
            }} size={'large'}>
                <Edit zpInfo={zpInfo} setParentZpInfo={async (zpInfo) => {
                    setZpInfo(zpInfo)
                    setEditVisible(false)
                    console.log("储存转盘信息")
                    let r = await sendMessage({
                        type: "setZpInfo",
                        zpInfo
                    })
                    console.log(r)

                }}/>
                <div style={{
                    position: "absolute",
                    textAlign: "right",
                    bottom: 60,
                    right: 20,
                    lineHeight: 1.5,
                    fontSize: 14,
                    color: "#666"
                }}>
                    <a href="mailto:v@cm321.cn">联系我们</a>
                    <br/>
                    Powered by 乘梦科技

                </div>

            </SideSheet>
        </div>
    </>);
};

export default App;