import React, {useEffect, useRef} from "react";

const Wheel = ({ options, wheelSize }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        drawWheel(context, options, wheelSize);
    }, [options, wheelSize]);

    const drawWheel = (ctx, options, size) => {
        console.log("重新绘制转盘canvas")
        ctx.clearRect(0, 0, size, size); // 清除之前的绘图
        const centerX = size / 2;
        const centerY = size / 2;
        const radius = size / 2 - 5;

        // 画个外圆
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, radius + 5, 0, 2*Math.PI, true);
        ctx.fillStyle = "#eee";
        ctx.fill();

        const totalWeight = options.reduce((acc, option) => acc + option.weight, 0);
        let startAngle = -Math.PI / 2 + (2 * Math.PI) * (options[0].weight / totalWeight) / 2;
        options.forEach((option) => {
            // 当前扇形的角度
            const sliceAngle = (2 * Math.PI) * (option.weight / totalWeight);

            // 绘制扇形
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.arc(centerX, centerY, radius, startAngle, startAngle - sliceAngle, true);
            ctx.closePath();
            ctx.fillStyle = option.color;
            ctx.fill();

            // 绘制扇形间的黑色分隔线
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.lineTo(centerX + radius * Math.cos(startAngle), centerY + radius * Math.sin(startAngle));
            // ctx.moveTo(centerX, centerY);
            // ctx.lineTo(centerX + radius * Math.cos(startAngle - sliceAngle), centerY + radius * Math.sin(startAngle + sliceAngle));
            ctx.strokeStyle = '#fff';
            ctx.lineWidth = 2;
            ctx.stroke();

            startAngle -= sliceAngle;
        });
    };
    

    // 转盘文字
    const renderTextElements = () => {
        const totalWeight = options.reduce((acc, option) => acc + option.weight, 0);
        let accumulatedAngle = Math.PI / 2 + (2 * Math.PI) * (options[0].weight / totalWeight) / 2;

        return options.map((option, index) => {
            // 当前扇形的角度
            const sliceAngle = (2 * Math.PI) * (option.weight / totalWeight);
            const angle =  (accumulatedAngle - sliceAngle/2) * (180 / Math.PI);
            accumulatedAngle -= sliceAngle;

            const style = {
                width: wheelSize,
                transform: `translateY(-50%) rotate(${angle}deg)`,
            };

            return <div className={'wheel-segment'} key={index} style={style}>
                <div className={'wheel-segment-text'} style={{width: "50%"}}>{option.text}</div>
            </div>;
        });
    };


    return (<div style={{position:"relative"}}>
        <canvas ref={canvasRef} width={wheelSize} height={wheelSize}></canvas>
        {renderTextElements()}
    </div>)
};

export default Wheel;