export default [
    '#ea7778', '#f7e175', '#a1e9ba', '#98e0f6', '#af8de7',
    '#e789b8', '#f2c265', '#6fba80', '#819be3', '#aa76be',
    '#e6787c', '#eeac5e', '#9edca6', '#9ce7f6', '#a988c6',
    '#e76980', '#efe475', '#75cf99', '#8bcd9e', '#a183b8',
    '#e45f61', '#e5d27d', '#76b287', '#93d9d8', '#9871a3',
    '#e4879b', '#e0bb63', '#97c353', '#87cabe', '#7971c2',
    "#d27d9e", "#e99c60", "#90b74d", "#7fcee0", "#6d67ae",
    "#ce66ac", "#e9ad3c", "#84a546", "#4fabf2", "#534b9b",
    "#df53ca", "#e2983c", "#7d8e37", "#3d89db", "#423e89",
    "#df509e", "#ce8036", "#646d38", "#ffffff", "#000000",
]