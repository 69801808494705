import './App.css';
import Turns from "./turns/turns"

function App() {
  return (
    <div className="App">
      <Turns />
    </div>
  );
}

export default App;
