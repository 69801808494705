import React, { useRef, useEffect, useState } from 'react';
import "./index.css"
import Wheel from "./wheel"
import {Button, ButtonGroup, Card, Col, Input, InputGroup, InputNumber, Row} from "@douyinfe/semi-ui";
import {IconDelete, IconSave} from "@douyinfe/semi-icons";
import colors from "../colors";

const audioWin = "http://pan.jialidun.vip/audio/newr.mp3"
const audioBa = "http://pan.jialidun.vip/audio/y2394.wav"
const App = ({zpInfo, setParentZpInfo}) => {
    const [wheelSize, setWheelSize] = useState(300); // 设置转盘大小
    let editOptions = zpInfo.options
    const [options, setOptions] = useState(editOptions);
    const [currentOption, setCurrentOption] = useState(null);
    const [rotation, setRotation] = useState(0);
    const [title, setTitle] = useState("")

    useEffect(()=>{
        let zpInfoClone = JSON.parse(JSON.stringify(zpInfo))
        setOptions(zpInfoClone.options)
        setTitle(zpInfoClone.title)
    },[zpInfo])

    const audioWinRef = useRef(null)
    const audioBaRef = useRef(null)
    useEffect(() => {
        audioWinRef.current.src = audioWin
        audioBaRef.current.src = audioBa
    }, [])

    // 窗口大小变化时，调整转盘大小
    useEffect(() => {
        const updateSize = () => {
            setWheelSize(Math.min(window.innerWidth, window.innerHeight) * 0.5);
        };
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const wheelContainerRef = useRef(null);
    const wheelInfo = useRef({
        currentOption: null,
        lastRotation:0, // 上一次旋转的角度
        spinStatus: false, // 是否正在旋转
        spinInterval:0,
        totalWeight: 0,
        firstOptionDeg: 0,
        degreesPerOption: 0,
        weightOptionIndex: [],
        result: {},
        lastRotate: 0
    })

    useEffect(() => {
        wheelInfo.current.currentOption = currentOption
    }, [currentOption]);

    useEffect(() => {
        //  options 变化时更新totalWeight 等参数
        const totalWeight = options.reduce((acc, option) => acc + option.weight, 0);
        const degreesPerOption = 360 / totalWeight;
        let firstOptionDeg = 360 * (options[0].weight / totalWeight);

        let weightOptionIndex = []
        for (let i=0;i<options.length;i++) {
            for (let j = 0; j < options[i].weight; j++) {
                weightOptionIndex.push(i)
            }
        }
        wheelInfo.current.totalWeight = totalWeight;
        wheelInfo.current.firstOptionDeg = firstOptionDeg;
        wheelInfo.current.degreesPerOption = degreesPerOption;
        wheelInfo.current.weightOptionIndex = weightOptionIndex

    },[options])

    const spinWheel = () => {
        if (wheelInfo.current.spinStatus) {
            // 正在旋转，就清理上一次的闭包
            clearInterval(wheelInfo.current.spinInterval)
        }

        // 从给定范围内获取随机值
        // 假如排除 选项-1
        let excludeText = ["选项-1", "选项-3"]
        let leftIndex = []
        for (let i=0;i<options.length;i++) {
            if (!excludeText.includes(options[i].text)) {
                leftIndex.push(i)
            }
        }
        let randomIndexOfLeft = Math.floor(Math.random() * leftIndex.length);
        let randomIndex = leftIndex[randomIndexOfLeft]
        // 根据option 的 targetIndex 获该项目的 中心权重
        let accumulatedWeight = 0
        for (let i=0;i<options.length; i++) {
            accumulatedWeight += options[i].weight
            if (i === randomIndex) {
                // accumulatedWeight += options[i].weight / 2
                break
            }
        }

        // console.log("accumulatedWeight", accumulatedWeight)
        // 获取该项的角度
        let randomDeg = accumulatedWeight * wheelInfo.current.degreesPerOption + 360 * 5
        // console.log("randomDeg", randomDeg)

        // console.log("结果是:", options[weightOptionIndex[randomIndex]], randomDeg)
        wheelInfo.current.result = options[randomIndex]

        let lastRotation = wheelInfo.current.lastRotation || 0;
        // 应该补正lastRotation 到初始化的位置，然后加上本次旋转的度数
        let diff = 360 - lastRotation % 360

        const newRotation = randomDeg + lastRotation + diff; // 至少旋转5圈
        wheelInfo.current.lastRotation = newRotation;
        setRotation(newRotation);
        wheelInfo.current.spinStatus = true

        wheelInfo.current.spinInterval = setInterval(() => {
            if (!wheelInfo.current.spinStatus){ // 不在旋转状态时，就停止
                clearInterval(wheelInfo.current.spinInterval)
            }
            if (wheelContainerRef.current) {
                const style = window.getComputedStyle(wheelContainerRef.current);
                const matrix = style.transform || style.webkitTransform || style.mozTransform;
                updateCurrentOption(extractRotationAngle(matrix));
            }
        }, 100); // 每100毫秒检查一次
    };

    const extractRotationAngle = (transformString) => {
        // console.log("transformString", transformString)
        const values = transformString.split('(')[1].split(')')[0].split(',');
        const a = values[0];
        const b = values[1];
        return Math.round(Math.atan2(b, a) * (180 / Math.PI));
    };

    const updateCurrentOption = (currentAngle) => {
        const firstOptionDeg = wheelInfo.current.firstOptionDeg;
        const degreesPerOption = wheelInfo.current.degreesPerOption;
        // console.log("当前角度111:", currentAngle % 360);
        // 修正角度，使得0度在12点钟方向
        let adjustedAngle = (currentAngle + firstOptionDeg/2) % 360;

        if (adjustedAngle < 0) {
            adjustedAngle = 360 + adjustedAngle;
        }


        let accumulatedAngle = 0
        for (let i = 0; i < options.length; i++) {
            accumulatedAngle += options[i].weight * degreesPerOption;
            if (adjustedAngle < accumulatedAngle) {
                if (!wheelInfo.current.currentOption || options[i].text !== wheelInfo.current.currentOption.text) {
                    // console.log("切换选项", options[i], adjustedAngle, accumulatedAngle,i, options.length,degreesPerOption)
                    setCurrentOption(options[i]);
                    audioBaRef.current.currentTime = 0
                    audioBaRef.current.play()
                }
                break
            }
        }
    };

    const handleSpinEnd = () => {
        // const style = window.getComputedStyle(wheelContainerRef.current);
        // const matrix = style.transform || style.webkitTransform || style.mozTransform;
        // updateCurrentOption(extractRotationAngle(matrix));
        // console.log("转盘停止,结果", wheelInfo.current.result)

        audioWinRef.current.currentTime = 0
        audioWinRef.current.play()
        wheelInfo.current.spinStatus = false
    };

    function handleDeleteOption(i) {
        options.splice(i,1)
        setOptions([...options])
    }
    function handleOptionChange(value,i,type) {
        // console.log(value, i, type)
        if (type==="text"){
            options[i].text = value
            setOptions([...options])
        }else if (type === "weight"){
            if (Number.isNaN(value - 0)) return;
            if (Number(value) > 99) return;
            if (value-0 < 1) return
            options[i].weight = value - 0
            setOptions([...options])
        }else if (type === "color"){
            options[i].color = value
            setOptions([...options])
        }

    }

    function handleAddOption() {
        options.push({
            text: '',
            color: colors[options.length],
            weight: 1
        })
        setOptions([...options])

    }

    let optionsDiv = []
    for (let i=0;i<options.length;i++) {
        optionsDiv.push(<InputGroup className={'item'} key={i}>
            <Input showClear placeholder="请输入转盘选项" style={{ width: 200 }} type="text" value={options[i].text} onChange={(value) => handleOptionChange(value, i, "text")}/>
            <Input type="color" style={{ width: 50 }} value={options[i].color} onChange={(value) => handleOptionChange(value, i, "color")}/>
            <InputNumber min={1} max={100} style={{ width: 66,textAlign:"center" }} value={options[i].weight} onChange={(value) => handleOptionChange(value, i, "weight")}/>
            <Button icon={<IconDelete />} type={'danger'} onClick={() => handleDeleteOption(i)}></Button>
        </InputGroup>)
    }

    return (<>
        <audio id="audioWin" ref={audioWinRef} preload="auto"></audio>
        <audio id="audioBa" ref={audioBaRef} preload="auto"></audio>
        <Row>
            <Col span={12}>
                <div>
                    <div className={"item"}>
                        <Input showClear prefix={'转盘名称'}
                               value={title}
                               onChange={(v)=>{
                                   console.log(v)
                                   setTitle(v)
                               }}
                               placeholder={'请输入转盘名称'}></Input>
                    </div>
                    <Card title={"转盘选项"}>
                        <div>
                            <div style={{
                                display: "flex",
                                padding: "5px 10px",
                                textAlign: "center",
                                fontSize: "12px",
                                color: "#666"
                            }}>
                                <div style={{width:200}}></div>
                                <div style={{width: 50}}>颜色</div>
                                <div style={{width:50}}>
                                    权重
                                </div>

                            </div>
                        </div>
                        {optionsDiv}
                        <br/>
                        <ButtonGroup style={{marginTop: "20px"}}>
                            <Button
                                onClick={handleAddOption}>添加选项</Button>
                            {/*<Button onClick={()=>{}}>批量添加</Button>*/}
                            {/*<Button onClick={()=>{}}>从多维表格导入</Button>*/}
                        </ButtonGroup>
                    </Card>
                    {/*<Card title={"转盘设置"} style={{marginTop:"20px"}}>*/}
                    {/*    <div className={'item'}>*/}
                    {/*        选项不重复抽取*/}
                    {/*        <Switch></Switch>*/}
                    {/*    </div>*/}
                    {/*    <div className={'item'}>*/}
                    {/*        转盘转动时间*/}
                    {/*        <InputNumber style={{width:100}} />*/}
                    {/*    </div>*/}
                    {/*</Card>*/}

                </div>
                <div style={{marginTop: "30px"}}>
                    <Button theme={'solid'}
                            icon={<IconSave/>}
                            size={'large'}
                            onClick={()=>{
                                console.log("保存转盘")
                                setParentZpInfo({
                                    title: title,
                                    options: options
                                })

                            }}
                    >保存转盘</Button>
                </div>
            </Col>
            <Col span={12}>
                <div style={{
                    position: "relative",
                    width: "fit-content",
                    margin: "auto"
                }}>
                    <div ref={wheelContainerRef} className="wheel-container"
                         style={{transform: `rotate(${rotation}deg)`, fontSize:"10px"}}
                         onTransitionEnd={handleSpinEnd}>
                        <Wheel options={options} wheelSize={wheelSize/1.6}/>
                    </div>
                    <div style={{fontSize:"5px"}} className={'pointer'} onClick={spinWheel}>
                        <div className={'text'}>Go</div>
                    </div>
                </div>
            </Col>
        </Row>
    </>);
};

export default App;